<script setup>
useHead({
  charset: 'utf-8',
  title: import.meta.env.VITE_APP_TITLE,
  titleTemplate: (title) =>
    title === import.meta.env.VITE_APP_TITLE ? title : `${title}-${import.meta.env.VITE_APP_TITLE}`,
  link: [
    {
      rel: 'icon',
      href: '/favicon.ico',
      sizes: 'any'
    }
  ],
  meta: [
    {
      name: 'viewport',
      content: 'width=device-width,initial-scale=1,user-scalable=no'
    },
    {
      name: 'description',
      content: '免费在线观看,电影,电视剧,动漫,视频网站,高清视频,Series,Movie,TV Shows,Online Free Watch'
    }
  ],
  script: [{ src: '/js/zhuge.js' }, { src: '/js/rem.js' }]
})

const nuxtApp = useNuxtApp()
nuxtApp.hook('vue:error', (e) => {
  console.log(e)
})

if (process.client) {
  try {
    await useUser().handleUserInfo()
  } catch (error) {}
  await useUser().handleAutoRegister()
  useCommon().reportDomain()
}
</script>

<template>
  <NuxtLoadingIndicator />
  <NuxtLayout>
    <NuxtPage />
  </NuxtLayout>
</template>

<style>
html,
body,
#__nuxt {
  /* position: relative; */
  /* height: 100vh; */
  background: #111425;
  color: #333;
  color-scheme: dark;
  user-select: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-tap-highlight-color: transparent;
}

.van-tabs__nav {
  background: #111425;
  color: #e8e8e8;
  font-size: 14px;
}
.van-tab {
  color: #fff;
}
.van-tab--active {
  color: #fd5f03;
  font-size: 18px;
}
.van-tabs__line {
  width: 0;
}
.van-nav-bar {
  background: transparent;
}
.van-nav-bar__content {
  background: transparent;
}
.van-nav-bar__title {
  color: #ffffff;
}
.van-nav-bar .van-icon {
  color: #ffffff;
}
.van-hairline--bottom:after {
  border-bottom-width: 0;
}
.van-pull-refresh {
  overflow: auto;
}
</style>
