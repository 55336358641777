import validate from "/build/node_modules/.pnpm/nuxt@3.12.4_@types+node@20.14.11_eslint@8.57.0_rollup@2.79.1_terser@5.31.3_typescript@5.0.2_vite@5.3.4_vue-tsc@1.8.27/node_modules/nuxt/dist/pages/runtime/validate.js";
import auth_45global from "/build/middleware/auth.global.ts";
import manifest_45route_45rule from "/build/node_modules/.pnpm/nuxt@3.12.4_@types+node@20.14.11_eslint@8.57.0_rollup@2.79.1_terser@5.31.3_typescript@5.0.2_vite@5.3.4_vue-tsc@1.8.27/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  auth_45global,
  manifest_45route_45rule
]
export const namedMiddleware = {
  user: () => import("/build/middleware/user.ts")
}