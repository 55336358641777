enum CacheName {
  Token = 'c-t',
  LoginStatus = 'c-ls',
  Fingers = 'c-fgs',
  MemberServiceUrl = 'c-mcsl'
}

const storage = process.client ? localStorage : null

class Cache {
  static setMemberServiceUrl = (val: string) => storage?.setItem(CacheName.MemberServiceUrl, val)

  static getMemberServiceUrl = () => storage?.getItem(CacheName.MemberServiceUrl)

  static fingers = () => storage?.getItem(CacheName.Fingers) ?? ''

  static setFingers = (val: string) => storage?.setItem(CacheName.Fingers, val)

  static token = () => storage?.getItem(CacheName.Token) ?? ''

  static removeToken = () => storage?.removeItem(CacheName.Token)

  static setToken = (val: string) => storage?.setItem(CacheName.Token, val)

  static loginStatus = () => storage?.getItem(CacheName.LoginStatus) ?? undefined

  static setLoginStatus = (val: string | number) => storage?.setItem(CacheName.LoginStatus, val)

  static removeLoginStatus = () => storage?.removeItem(CacheName.LoginStatus)

  // 清空所有状态
  static clearCache = () => {
    this.removeToken()
    this.removeLoginStatus()
  }
}

export default Cache
