import { openNewWindowAsync } from '~/utils/helper'
import {
  getCommonDictApi,
  reportDomainApi,
  sendCodeWithPhoneApi,
  sendCodeWithoutPhoneApi,
  userFindPassword
} from '~/api/common'
import type { CodeWithPhoneParams, FindPasswordParams } from '~/api/common/model'
import Cache from '~/utils/cache'

export const useCommon = defineStore('common', {
  state: () => ({}),
  actions: {
    // 获取验证码
    async handleCodePhone(arg: CodeWithPhoneParams) {
      if (useUser().isBindPhone) {
        await sendCodeWithoutPhoneApi()
      } else {
        await sendCodeWithPhoneApi(arg)
      }
    },
    // 域名访问统计
    async reportDomain() {
      const params = {
        registerReference: process.client ? location.host : undefined
      }
      await reportDomainApi(params)
    },
    // 找回密码
    async handleFindPsd(arg: FindPasswordParams) {
      const params = {
        ...arg,
        deviceNo: Cache.fingers()
      }
      const { data } = await userFindPassword(params as FindPasswordParams)
      Cache.setToken(data.token)
      await useUser().handleUserInfo()
    },
    // 客服
    async openCustomerService() {
      let serviceUrl = Cache.getMemberServiceUrl() || ''
      openNewWindowAsync(async ({ openWin }) => {
        try {
          const { data } = await getCommonDictApi({ key: 'common.member.service.web' })
          data[0]?.value && (serviceUrl = data[0]?.value)
          Cache.setMemberServiceUrl(serviceUrl)
          openWin(serviceUrl)
        } catch (err) {
          openWin(serviceUrl)
        }
      })
    }
  }
})
