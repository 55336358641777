import revive_payload_client_R1if7KaM5B from "/build/node_modules/.pnpm/nuxt@3.12.4_@types+node@20.14.11_eslint@8.57.0_rollup@2.79.1_terser@5.31.3_typescript@5.0.2_vite@5.3.4_vue-tsc@1.8.27/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_2zm51BJdJ6 from "/build/node_modules/.pnpm/nuxt@3.12.4_@types+node@20.14.11_eslint@8.57.0_rollup@2.79.1_terser@5.31.3_typescript@5.0.2_vite@5.3.4_vue-tsc@1.8.27/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_hYOKiU4GGE from "/build/node_modules/.pnpm/nuxt@3.12.4_@types+node@20.14.11_eslint@8.57.0_rollup@2.79.1_terser@5.31.3_typescript@5.0.2_vite@5.3.4_vue-tsc@1.8.27/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import navigation_repaint_client_cOTkwHP14v from "/build/node_modules/.pnpm/nuxt@3.12.4_@types+node@20.14.11_eslint@8.57.0_rollup@2.79.1_terser@5.31.3_typescript@5.0.2_vite@5.3.4_vue-tsc@1.8.27/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_Yl7ne23DhN from "/build/node_modules/.pnpm/nuxt@3.12.4_@types+node@20.14.11_eslint@8.57.0_rollup@2.79.1_terser@5.31.3_typescript@5.0.2_vite@5.3.4_vue-tsc@1.8.27/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_X55XMxW8Wa from "/build/node_modules/.pnpm/nuxt@3.12.4_@types+node@20.14.11_eslint@8.57.0_rollup@2.79.1_terser@5.31.3_typescript@5.0.2_vite@5.3.4_vue-tsc@1.8.27/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_8djG8o2ziw from "/build/node_modules/.pnpm/@pinia+nuxt@0.4.11_rollup@2.79.1_typescript@5.0.2_vue@3.4.33/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.mjs";
import components_plugin_KR1HBZs4kY from "/build/.nuxt/components.plugin.mjs";
import prefetch_client_L2IqrLAqnY from "/build/node_modules/.pnpm/nuxt@3.12.4_@types+node@20.14.11_eslint@8.57.0_rollup@2.79.1_terser@5.31.3_typescript@5.0.2_vite@5.3.4_vue-tsc@1.8.27/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_4lnPNOPGP2 from "/build/node_modules/.pnpm/dayjs-nuxt@2.1.9_rollup@2.79.1/node_modules/dayjs-nuxt/dist/runtime/plugin.mjs";
import plugin_dwI2zYocqR from "/build/node_modules/.pnpm/@nuxtjs+device@3.1.1_rollup@2.79.1/node_modules/@nuxtjs/device/dist/runtime/plugin.mjs";
import plugin_client_xhtqi0s0de from "/build/node_modules/.pnpm/nuxt-gtag@1.2.1_rollup@2.79.1/node_modules/nuxt-gtag/dist/runtime/plugin.client.mjs";
import unocss_MzCDxu9LMj from "/build/.nuxt/unocss.mjs";
import icon_client_shIZuwSQPw from "/build/plugins/icon.client.ts";
import message_client_gNVFdLD78f from "/build/plugins/message.client.ts";
export default [
  revive_payload_client_R1if7KaM5B,
  unhead_2zm51BJdJ6,
  router_hYOKiU4GGE,
  navigation_repaint_client_cOTkwHP14v,
  check_outdated_build_client_Yl7ne23DhN,
  chunk_reload_client_X55XMxW8Wa,
  plugin_vue3_8djG8o2ziw,
  components_plugin_KR1HBZs4kY,
  prefetch_client_L2IqrLAqnY,
  plugin_4lnPNOPGP2,
  plugin_dwI2zYocqR,
  plugin_client_xhtqi0s0de,
  unocss_MzCDxu9LMj,
  icon_client_shIZuwSQPw,
  message_client_gNVFdLD78f
]