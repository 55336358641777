import { default as indexs8omXmYg7XMeta } from "/build/pages/activity/index.vue?macro=true";
import { default as _91id_93CYPa0q4NZzMeta } from "/build/pages/activityDetail/[id].vue?macro=true";
import { default as indexKO5uS6y4TzMeta } from "/build/pages/activityIframe/index.vue?macro=true";
import { default as index5Gwgtq9cMBMeta } from "/build/pages/bindPhone/index.vue?macro=true";
import { default as indexjSqJRpdQh9Meta } from "/build/pages/boon/index.vue?macro=true";
import { default as indexo5bp2BTTa9Meta } from "/build/pages/browsHistory/index.vue?macro=true";
import { default as index2ZlDthkmyvMeta } from "/build/pages/cdkGift/index.vue?macro=true";
import { default as indexc6yNBsjuDuMeta } from "/build/pages/changeAcount/index.vue?macro=true";
import { default as _91type_93AA5YhewHbGMeta } from "/build/pages/channel/[type].vue?macro=true";
import { default as index8Z5ufrZJVyMeta } from "/build/pages/demo/img-load/index.vue?macro=true";
import { default as index9aU7lRQQg6Meta } from "/build/pages/demo/player/index.vue?macro=true";
import { default as indexZ4gfhQVmTlMeta } from "/build/pages/demo/utils/index.vue?macro=true";
import { default as _91id_93ehWrDUYovYMeta } from "/build/pages/details/[type]/[id].vue?macro=true";
import { default as indexePIQXbWMqGMeta } from "/build/pages/feedback/index.vue?macro=true";
import { default as indexd2k7G0oCB9Meta } from "/build/pages/flowDetail/index.vue?macro=true";
import { default as indexHk8dCkjARjMeta } from "/build/pages/flowRecord/index.vue?macro=true";
import { default as indexbszKR15obSMeta } from "/build/pages/group/index.vue?macro=true";
import { default as indexTPtH4BqImuMeta } from "/build/pages/home-free/index.vue?macro=true";
import { default as indexO9wNJw6k5xMeta } from "/build/pages/home-vip/index.vue?macro=true";
import { default as indexVPotO5VXkXMeta } from "/build/pages/id-card-find/index.vue?macro=true";
import { default as indexC26jEVn5HZMeta } from "/build/pages/index/index.vue?macro=true";
import { default as indexqBN8wIQgbgMeta } from "/build/pages/memberRecharge/index.vue?macro=true";
import { default as _91producerId_93X8nvX9AA0VMeta } from "/build/pages/merchant_details/[producerId].vue?macro=true";
import { default as indexI2keVnlV07Meta } from "/build/pages/merchant/index.vue?macro=true";
import { default as index56ufYyR5GCMeta } from "/build/pages/mine/index.vue?macro=true";
import { default as indexRegDr5nUSsMeta } from "/build/pages/myCollected/index.vue?macro=true";
import { default as index_32copyqZ5gJ6MJ9zMeta } from "/build/pages/myDownload/index copy.vue?macro=true";
import { default as indexCS4RdPUbGFMeta } from "/build/pages/myDownload/index.vue?macro=true";
import { default as indexDoVztbhOneMeta } from "/build/pages/payResult/index.vue?macro=true";
import { default as _91serial_935R4EUQxIMmMeta } from "/build/pages/play/[type]/[id]/[serial].vue?macro=true";
import { default as _91id_933pD3JgjxQWMeta } from "/build/pages/player/[id].vue?macro=true";
import { default as indexQleszLvtyjMeta } from "/build/pages/profile/index.vue?macro=true";
import { default as indexsIwI4VBhMwMeta } from "/build/pages/promote-share-record/index.vue?macro=true";
import { default as indexxMllq7ufTuMeta } from "/build/pages/promote-share/index.vue?macro=true";
import { default as indexbfqbgOa4EqMeta } from "/build/pages/purchase-history/index.vue?macro=true";
import { default as _91rankType_93UcICKfYWd6Meta } from "/build/pages/ranking-list/all/[periodType]/[rankType].vue?macro=true";
import { default as indexmmgEqhJbS7Meta } from "/build/pages/ranking-list/index.vue?macro=true";
import { default as indexrPdlgJ2QcVMeta } from "/build/pages/recharge/index.vue?macro=true";
import { default as _91keyword_93jpvkPtNXTHMeta } from "/build/pages/search/[keyword].vue?macro=true";
import { default as indexYrwjH4U8Q2Meta } from "/build/pages/service/index.vue?macro=true";
import { default as indexWu0c2uiBh5Meta } from "/build/pages/sis/index.vue?macro=true";
import { default as indexGOjN1FhCVrMeta } from "/build/pages/taskCenter/index.vue?macro=true";
import { default as indexiW9Lv5T0vJMeta } from "/build/pages/usePhone/index.vue?macro=true";
import { default as index3WLKlxfNXqMeta } from "/build/pages/user/index.vue?macro=true";
export default [
  {
    name: "activity",
    path: "/activity",
    component: () => import("/build/pages/activity/index.vue").then(m => m.default || m)
  },
  {
    name: "activityDetail-id",
    path: "/activityDetail/:id()",
    component: () => import("/build/pages/activityDetail/[id].vue").then(m => m.default || m)
  },
  {
    name: "activityIframe",
    path: "/activityIframe",
    component: () => import("/build/pages/activityIframe/index.vue").then(m => m.default || m)
  },
  {
    name: "bindPhone",
    path: "/bindPhone",
    component: () => import("/build/pages/bindPhone/index.vue").then(m => m.default || m)
  },
  {
    name: "boon",
    path: "/boon",
    component: () => import("/build/pages/boon/index.vue").then(m => m.default || m)
  },
  {
    name: "browsHistory",
    path: "/browsHistory",
    component: () => import("/build/pages/browsHistory/index.vue").then(m => m.default || m)
  },
  {
    name: "cdkGift",
    path: "/cdkGift",
    component: () => import("/build/pages/cdkGift/index.vue").then(m => m.default || m)
  },
  {
    name: "changeAcount",
    path: "/changeAcount",
    component: () => import("/build/pages/changeAcount/index.vue").then(m => m.default || m)
  },
  {
    name: "channel-type",
    path: "/channel/:type()",
    meta: _91type_93AA5YhewHbGMeta || {},
    component: () => import("/build/pages/channel/[type].vue").then(m => m.default || m)
  },
  {
    name: "demo-img-load",
    path: "/demo/img-load",
    component: () => import("/build/pages/demo/img-load/index.vue").then(m => m.default || m)
  },
  {
    name: "demo-player",
    path: "/demo/player",
    component: () => import("/build/pages/demo/player/index.vue").then(m => m.default || m)
  },
  {
    name: "demo-utils",
    path: "/demo/utils",
    component: () => import("/build/pages/demo/utils/index.vue").then(m => m.default || m)
  },
  {
    name: "details-type-id",
    path: "/details/:type()/:id()",
    meta: _91id_93ehWrDUYovYMeta || {},
    component: () => import("/build/pages/details/[type]/[id].vue").then(m => m.default || m)
  },
  {
    name: "feedback",
    path: "/feedback",
    component: () => import("/build/pages/feedback/index.vue").then(m => m.default || m)
  },
  {
    name: "flowDetail",
    path: "/flowDetail",
    component: () => import("/build/pages/flowDetail/index.vue").then(m => m.default || m)
  },
  {
    name: "flowRecord",
    path: "/flowRecord",
    component: () => import("/build/pages/flowRecord/index.vue").then(m => m.default || m)
  },
  {
    name: "group",
    path: "/group",
    component: () => import("/build/pages/group/index.vue").then(m => m.default || m)
  },
  {
    name: "home-free",
    path: "/home-free",
    component: () => import("/build/pages/home-free/index.vue").then(m => m.default || m)
  },
  {
    name: "home-vip",
    path: "/home-vip",
    component: () => import("/build/pages/home-vip/index.vue").then(m => m.default || m)
  },
  {
    name: "id-card-find",
    path: "/id-card-find",
    component: () => import("/build/pages/id-card-find/index.vue").then(m => m.default || m)
  },
  {
    name: "index",
    path: "/",
    component: () => import("/build/pages/index/index.vue").then(m => m.default || m)
  },
  {
    name: "memberRecharge",
    path: "/memberRecharge",
    component: () => import("/build/pages/memberRecharge/index.vue").then(m => m.default || m)
  },
  {
    name: "merchant_details-producerId",
    path: "/merchant_details/:producerId()",
    component: () => import("/build/pages/merchant_details/[producerId].vue").then(m => m.default || m)
  },
  {
    name: "merchant",
    path: "/merchant",
    component: () => import("/build/pages/merchant/index.vue").then(m => m.default || m)
  },
  {
    name: "mine",
    path: "/mine",
    component: () => import("/build/pages/mine/index.vue").then(m => m.default || m)
  },
  {
    name: "myCollected",
    path: "/myCollected",
    component: () => import("/build/pages/myCollected/index.vue").then(m => m.default || m)
  },
  {
    name: "myDownload-index copy",
    path: "/myDownload/index%20copy",
    component: () => import("/build/pages/myDownload/index copy.vue").then(m => m.default || m)
  },
  {
    name: "myDownload",
    path: "/myDownload",
    component: () => import("/build/pages/myDownload/index.vue").then(m => m.default || m)
  },
  {
    name: "payResult",
    path: "/payResult",
    component: () => import("/build/pages/payResult/index.vue").then(m => m.default || m)
  },
  {
    name: "play-type-id-serial",
    path: "/play/:type()/:id()/:serial()",
    meta: _91serial_935R4EUQxIMmMeta || {},
    component: () => import("/build/pages/play/[type]/[id]/[serial].vue").then(m => m.default || m)
  },
  {
    name: "player-id",
    path: "/player/:id()",
    component: () => import("/build/pages/player/[id].vue").then(m => m.default || m)
  },
  {
    name: "profile",
    path: "/profile",
    component: () => import("/build/pages/profile/index.vue").then(m => m.default || m)
  },
  {
    name: "promote-share-record",
    path: "/promote-share-record",
    component: () => import("/build/pages/promote-share-record/index.vue").then(m => m.default || m)
  },
  {
    name: "promote-share",
    path: "/promote-share",
    component: () => import("/build/pages/promote-share/index.vue").then(m => m.default || m)
  },
  {
    name: "purchase-history",
    path: "/purchase-history",
    component: () => import("/build/pages/purchase-history/index.vue").then(m => m.default || m)
  },
  {
    name: "ranking-list-all-periodType-rankType",
    path: "/ranking-list/all/:periodType()/:rankType()",
    component: () => import("/build/pages/ranking-list/all/[periodType]/[rankType].vue").then(m => m.default || m)
  },
  {
    name: "ranking-list",
    path: "/ranking-list",
    component: () => import("/build/pages/ranking-list/index.vue").then(m => m.default || m)
  },
  {
    name: "recharge",
    path: "/recharge",
    component: () => import("/build/pages/recharge/index.vue").then(m => m.default || m)
  },
  {
    name: "search-keyword",
    path: "/search/:keyword()",
    component: () => import("/build/pages/search/[keyword].vue").then(m => m.default || m)
  },
  {
    name: "service",
    path: "/service",
    component: () => import("/build/pages/service/index.vue").then(m => m.default || m)
  },
  {
    name: "sis",
    path: "/sis",
    component: () => import("/build/pages/sis/index.vue").then(m => m.default || m)
  },
  {
    name: "taskCenter",
    path: "/taskCenter",
    component: () => import("/build/pages/taskCenter/index.vue").then(m => m.default || m)
  },
  {
    name: "usePhone",
    path: "/usePhone",
    component: () => import("/build/pages/usePhone/index.vue").then(m => m.default || m)
  },
  {
    name: "user",
    path: "/user",
    meta: index3WLKlxfNXqMeta || {},
    component: () => import("/build/pages/user/index.vue").then(m => m.default || m)
  }
]